/** @jsx jsx */
import { jsx, Text, Link, useColorMode } from "theme-ui";
import React from "react";
import { gridStyles, bodyColumn } from "../styles/shared.styles";
import { graphql } from "gatsby";
import PostList from "../components/post-list/Index";
import SEO from "../components/seo";

var { useEffect } = React;

const Blog = (props) => {
	var { allMdx } = props.data;

	return (
		<>
			<SEO title="Blog" />
			<main sx={{ ...gridStyles, marginTop: 5 }} role="main">
				<div sx={bodyColumn}>
					<Text variant="display" sx={{ marginBottom: 3 }} as="h1">
						Blog
					</Text>
					<Text variant="body" sx={{ color: "grays.60" }}>
						An exploration of design and dev ideas. Covering topics
						like React, Gatsby, Javascript, scalability in software,
						and systems design.
					</Text>
					<PostList data={allMdx} />
				</div>
			</main>
		</>
	);
};

export default Blog;

// gets access to all of my blog posts
export const query = graphql`
	query {
		allMdx(
			filter: { fields: { slug: { regex: "/blog/" } } }
			sort: { fields: frontmatter___date, order: DESC }
		) {
			edges {
				node {
					id
					frontmatter {
						title
						description
					}
					fileAbsolutePath
					fields {
						slug
					}
					body
				}
			}
		}
	}
`;
